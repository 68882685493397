@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/dist/css/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";


:root {
	--font-heading: 'Montserrat', 'sans-serif';
	--font-body: 'Karla', 'sans-serif';

	--color-foreground: hsla(0, 0%, 100%, 1);
  --color-text-50: hsla(0, 0%, 62%, 1);
  --color-text-100: hsl(230, 13%, 27%);
  --color-text-200: hsla(236, 8%, 35%, 1);
  --color-sub-text: hsla(210, 16%, 59%, 1);

  --color-brand: hsla(196, 100%, 47%, 1);

  --color-disabled: hsla(0, 0%, 75%, 1);
  --color-text-link: hsla(202, 53%, 48%, 1);

  --color-body-background: hsla(210, 20%, 96%, 1);
  --color-textarea-border: hsla(225, 4%, 79%, 1);
  --font-heading: "Montserrat";
  --font-body: "Montserrat";
  --color-button-border: hsla(210, 17%, 91%, 1);
  --color-edit-background: hsla(240, 4%, 95%, 1);
  --color-required: hsl(0, 100%, 50%);
  --color-note-banner: hsl(50, 96%, 89%);
}

html, body, sop-root {
	height: 100%;
	font-family: var(--font-heading);
}

* {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", "sans-serif";
	// outline: 1px solid red;
}

body {
	// font-size: calc(12px + (14 - 12) * ((100vw - 768px) / (1600 - 768)));
	font-size: 14px;
	color: #505050;
	background-color: #efefef;
	// overflow: hidden;
}
.no-padding {
	padding: 0 !important;
}

::-webkit-scrollbar {
	background-color: transparent;
	width: 1em;
}

::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-track-piece {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #d8d8d8;
	border-radius: 0.5em;
	// width: 85%;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

::-webkit-scrollbar-corner {
	display: none;
}

::-webkit-resizer {
	display: none;
}

.mat-dialog-container {
	padding: 0 !important;
}

// Loader

.spinner {
	animation: rotator 1.4s linear infinite;
	margin-bottom: 0.17em;
	height: 1.2em;
	width: 1.2em;
}
.checkmark {
	height: 1.2em;
	width: 1.2em;
}
@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash 1.4s ease-in-out infinite;
}

.highlighted {
	animation: pulseBorder 1s ease-in-out 0.2s 1;
}

@keyframes pulseBorder {
	0% {
		background: none;
	}
	25% {
		background-color: rgba(34, 243, 178, 0.3);
	}
	50% {
		background-color: rgba(34, 243, 178, 0.5);
	}
	75% {
		background-color: rgba(34, 243, 178, 0.3);
	}
	100% {
		background: none;
	}
	// 100% {
	// 	border-bottom: 2px solid #22f3b2;
	// }
}

@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}

	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}

mark {
	padding: 0;
}

.modal_container {
	@media screen and (min-width: 1024px) {
		max-width: 70vw !important;
	}
}
