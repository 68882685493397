@use '@angular/material' as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$isc-sop-analysis-primary: mat.define-palette(mat.$blue-palette, 500);
$isc-sop-analysis-accent: mat.define-palette(mat.$gray-palette, 50, 100, 200);

// The warn palette is optional (defaults to red).
$isc-sop-analysis-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$isc-sop-analysis-theme: mat.define-light-theme($isc-sop-analysis-primary, $isc-sop-analysis-accent, $isc-sop-analysis-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($isc-sop-analysis-theme);


html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
